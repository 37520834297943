#menu__toggle {
  opacity: 0;
}
.toggle > span {
  transform: rotate(45deg) !important;
}

.toggle > span::before {
  top: 0 !important;
  transform: rotate(0deg) !important;
}

.toggle > span::after {
  top: -1.5px !important;
  transform: rotate(90deg) !important;
}

.menu__btn {
  /* position: fixed; */
  /* top: 20px;
  left: 20px; */
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: relative;
  width: 100%;
  height: 2px;
  background-color: #616161;
  transition-duration: 0.25s;
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 6px;
}
