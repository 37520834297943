.header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.header_container h1 {
  font-size: 24px;
}

.main_container {
  padding: 20px;
  min-height: calc(100vh - 82px - 64px);
  max-width: 1024px;
  margin: 0px auto;
}

.footer_container {
  text-align: center;
  padding: 20px;
}

.icon {
  cursor: pointer;
}
